export const SRR_template = `
   Please refresh my devsite from the live site

   Logname:     {{logname}} 
   Devsite:     {{virtname}}
   LiveSite:    {{domain}}
   Additional Notes: 
--------------------
{{{notes}}}
--------------------

   Please send a message to the customer when this is set up.
`;

export const SRR_items = {
   logname: '',
   domain: '',
   virtname: '',
   notes: ''
};

export const SRR_subject_template = `Subject: Refresh devsite {{logname}}/{{domain}}`;

export const SRR_subject_items = { 
    logname: '',
    domain: ''
};
