export const AWH_template = `
   Please set up web hosting

   Logname: {{logname}} 
   Domain:  {{domain}}
   Hosting Plan: {{plan}}
   Profit/Non-profit: {{profit}}
   Install WordPress? {{wp_install}}
   Additional Notes: 
--------------------
{{{notes}}}

   This domain is hosted with us, and has no website currently.
   Please send a message to accounting when this is set up.
`;

export const AWH_items = {
   logname: '',
   plan: '',
   profit: '',
   wp_install: '',
   notes: ''
};

export const AWH_subject_template = `Subject: {{logname}} requests web hosting for {{domain}}`;

export const AWH_subject_items = { 
    logname: '',
    domain: ''
};
