export const SPR_template = `
   Please promote my devsite to live

   Logname:     {{logname}} 
   Devsite:     {{virtname}}
   LiveSite:    {{domain}}
   Additional Notes: 
--------------------
{{{notes}}}
--------------------

   Please send a message to the customer when this is set up.
`;

export const SPR_items = {
   logname: '',
   domain: '',
   virtname: '',
   notes: ''
};

export const SPR_subject_template = `Subject: Promote devsite {{logname}}/{{domain}}`;

export const SPR_subject_items = { 
    logname: '',
    domain: ''
};
