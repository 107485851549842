import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/Home";
import HelloWorld from "@/views/HelloWorld";
import ThankYou from "@/views/ThankYou";
import QuickRef from "@/views/QuickRef";
import SearchWiki from "@/views/SearchWiki";
import Unimplemented from "@/views/Unimplemented";
import Whois from "@/views/Whois";
import AccountInfo from "@/views/AccountInfo";
import AddSecondaries from "@/views/AddSecondaries";
import AddWebHosting from "@/views/AddWebHosting";
import EditCC from "@/views/EditCC";
import BillingAddress from "@/views/BillingAddress";
import AccountAddress from "@/views/AccountAddress";
import ChangeEmailPassword from "@/views/ChangeEmailPassword";
import ChangePanelPassword from "@/views/ChangePanelPassword";
import ResetPanelPassword from "@/views/ResetPanelPassword";
import ResetEmailPassword from "@/views/ResetEmailPassword";
import RequestPanelReset from "@/views/RequestPanelReset";
import RequestEmailReset from "@/views/RequestEmailReset";
import ChangeStmtDelivery from "@/views/ChangeStmtDelivery";
import SendAuthMsg from "@/views/SendAuthMsg";
import SubscribePortal from "@/views/SubscribePortal";
import AutoResponder from "@/views/AutoResponder";
import DiskUsage from "@/views/DiskUsage";
import DomainList from "@/views/DomainList";
import DomainUsage from "@/views/DomainUsage";
import DomReg from "@/views/DomReg";
import DomRegHelp from "@/views/DomRegHelp";
import DomHelp from "@/views/DomHelp";
import DomFAQ from "@/views/DomFAQ";
import DomainRenewal from "@/views/DomainRenewal";
import EditDomain from "@/views/EditDomain";
import EditTempEmail from "@/views/EditTempEmail";
import EmailAliases from "@/views/EmailAliases";
import UpdateEmailAlias from "@/components/UpdateEmailAlias";
import CreateEmailAlias from "@/components/CreateEmailAlias";
import TempEmail from "@/views/TempEmail";
import TempEmailFAQ from "@/views/TempEmailFAQ";
import RecoveryContacts from "@/views/RecoveryContacts";
import MakePayment from "@/views/MakePayment";
import PayForm from "@/components/PayForm";
import CoworkPayForm from "@/components/CoworkPayForm";
import Permissions from "@/views/Permissions";
import AllPermissions from "@/views/AllPermissions";
import Websites from "@/views/Websites";
import EditWebsite from "@/views/EditWebsite";
import WebAppInstall from "@/views/WebAppInstall";
import DevSites from "@/views/DevSites";
import DevSiteInfo from "@/views/DevSiteInfo";
import CreateDevsite from "@/views/CreateDevsite";
import Databases from "@/views/Databases";
import MD5 from "@/views/MD5";
import Pwned from "@/views/Pwned";
import Invoices from "@/views/Invoices";
import Secondaries from "@/views/Secondaries";
import SecPasswords from "@/views/SecPasswords";
import CoworkingProds from "@/views/CoworkingProds";
import Circuits from "@/views/Circuits";
import TechContacts from "@/views/TechContacts";
import AddTC from "@/views/AddTC";
import EditTC from "@/views/EditTC";
import BusAccounts from "@/views/BusAccounts";
import RecentPayments from "@/views/RecentPayments";
import ViewInvoice from "@/views/ViewInvoice";
import UnixTime from "@/views/UnixTime";
import Base64 from "@/views/Base64";
import GeneratePasswords from "@/views/GeneratePasswords";
import FAQTest from "@/views/FAQTest";
import FAQ from "@/components/FAQ";
import ShowLogs from "@/views/ShowLogs";
import MailingLists from "@/views/MailingLists";

export default createRouter({
  history: createWebHistory(),
  routes: [
    {
      name: "thankyou",
      path: "/thankyou",
      component: ThankYou
    },
    {
      name: "home",
      path: "/",
      component: Home
    },
    {
      name: "unimplemented",
      path: "/unimplemented",
      component: Unimplemented
    },
    {
      name: "searchwiki",
      path: "/searchwiki",
      component: SearchWiki
    },
    {
      name: "quickref",
      path: "/quickref",
      component: QuickRef
    },
    {
      name: "account-info",
      path: "/account-info",
      component: AccountInfo
    },
    {
      name: "add-webhosting",
      path: "/add-webhosting",
      component: AddWebHosting
    },
    {
      name: "add-secondaries",
      path: "/add-secondaries",
      component: AddSecondaries
    },
    {
      name: "secondaries",
      path: "/secondaries",
      component: Secondaries
    },
    {
      name: "sec-passwords",
      path: "/sec-passwords",
      component: SecPasswords
    },
    {
      name: "coworking-prods",
      path: "/coworking-prods",
      component: CoworkingProds
    },
    {
      name: "contacts",
      path: "/contacts",
      component: TechContacts
    },
    {
      name: "add-tc-contact",
      path: "/add-tc-contact",
      component: AddTC
    },
    {
      name: "edit-tc-contact",
      path: "/edit-tc-contact/:tcid",
      component: EditTC
    },
    {
      name: "circuit-list",
      path: "/circuit-list",
      component: Circuits
    },
    {
      name: "ba-list",
      path: "/ba-list",
      component: BusAccounts
    },
    {
      name: "change-email-password",
      path: "/change-email-password",
      component: ChangeEmailPassword
    },
    {
      name: "change-panel-password",
      path: "/change-panel-password",
      component: ChangePanelPassword
    },
    {
      name: "reset-panel-password",
      path: "/reset-panel-password/:token",
      component: ResetPanelPassword
    },
    {
      name: "reset-email-password",
      path: "/reset-email-password/:token",
      component: ResetEmailPassword
    },
    {
      name: "request-email-reset",
      path: "/request-email-reset",
      component: RequestEmailReset
    },
    {
      name: "request-panel-reset",
      path: "/request-panel-reset",
      component: RequestPanelReset
    },
    {
      name: "change-stmt-delivery",
      path: "/change-stmt-delivery",
      component: ChangeStmtDelivery
    },
    {
      name: "send-auth-msg",
      path: "/send-auth-msg",
      component: SendAuthMsg
    },
    {
      name: "subscribe-portal",
      path: "/subscribe-portal",
      component: SubscribePortal
    },
    {
      name: "auto-responder",
      path: "/auto-responder",
      component: AutoResponder
    },
    {
      name: "disk-usage",
      path: "/disk-usage",
      component: DiskUsage
    },
    {
      name: "domain-registration",
      path: "/domain-registration",
      component: DomReg
    },
    {
      name: "domain-registration-help",
      path: "/domain-registration-help",
      component: DomRegHelp
    },
    {
      name: "domain-list",
      path: "/domain-list",
      component: DomainList
    },
    {
      name: "renew-domains",
      path: "/renew-domains",
      component: DomainList
    },
    {
      name: "domain-permissions",
      path: "/domain-permissions",
      component: DomainList
    },
    {
      name: "remove-permissions",
      path: "/remove-permissions",
      component: AllPermissions
    },
    {
      name: "domain-usage",
      path: "/domain-usage",
      component: DomainUsage
    },
    {
      name: "domain-renewal",
      path: "/domain-renewal/:domain",
      component: DomainRenewal
    },
    {
      name: "email-aliases",
      path: "/email-aliases/:domain?",
      component: EmailAliases
    },
    {
      name: "update-email-alias",
      path: "/update-mail-alias/:domain/:src",
      component: UpdateEmailAlias
    },
    {
      name: "create-email-alias",
      path: "/create-mail-alias/:src_domain",
      component: CreateEmailAlias
    },
    {
      name: "edit-cc",
      path: "/edit-cc",
      component: EditCC
    },
    {
      name: "billing-addr",
      path: "/billing-addr",
      component: BillingAddress
    },
    {
      name: "account-addr",
      path: "/account-addr",
      component: AccountAddress
    },
    {
      name: "make-payment",
      path: "/make-payment",
      component: MakePayment
    },
    {
      name: "pay-form",
      path: "/pay-form",
      component: PayForm
    },
    {
      name: "cowork-pay-form",
      path: "/cowork-pay-form",
      component: CoworkPayForm
    },
    {
      name: "permissions",
      path: "/permissions/:domain",
      component: Permissions
    },
    {
      name: "edit-domain",
      path: "/edit-domain/:domain",
      component: EditDomain
    },
    {
      name: "edit-temp-email",
      path: "/edit-temp-email/:alias",
      component: EditTempEmail
    },
    {
      name: "websites",
      path: "/websites",
      component: Websites
    },
    {
      name: "edit-website",
      path: "/edit-website/:vid",
      component: EditWebsite
    },
    {
      name: "webappinstall",
      path: "/webappinstall",
      component: WebAppInstall
    },
    {
      name: "devsite-info",
      path: "/devsite-info/:vid",
      component: DevSiteInfo
    },
    {
      name: "devsites",
      path: "/devsites",
      component: DevSites
    },
    {
      name: "create-devsite",
      path: "/create-devsite",
      component: CreateDevsite
    },
    {
      name: "databases",
      path: "/databases",
      component: Databases
    },
    {
      name: "dom-help",
      path: "/dom-help",
      component: DomHelp
    },
    {
      name: "dom-faq",
      path: "/dom-faq",
      component: DomFAQ
    },
    {
      name: "temp-email",
      path: "/temp-email",
      component: TempEmail
    },
    {
      name: "temp-email-faq",
      path: "/temp-email-faq",
      component: TempEmailFAQ
    },
    {
      name: "recovery-contacts",
      path: "/recovery-contacts",
      component: RecoveryContacts
    },
    {
      name: "invoices",
      path: "/invoices",
      component: Invoices
    },
    {
      name: "recent-payments",
      path: "/recent-payments",
      component: RecentPayments
    },
    {
      name: "view-invoice",
      path: "/view-invoice/:invoice",
      component: ViewInvoice
    },
    {
      name: "pwned",
      path: "/pwned",
      component: Pwned
    },
    {
      name: "md5",
      path: "/md5",
      component: MD5
    },
    {
      name: "base64",
      path: "/bas64",
      component: Base64
    },
    {
      name: "unix-time",
      path: "/unix-time",
      component: UnixTime
    },
    {
      name: "generate-passwords",
      path: "/generate-passwords",
      component: GeneratePasswords
    },
    {
      name: "faq-test",
      path: "/faq-test",
      component: FAQTest
    },
    {
      name: "whois",
      path: "/whois/:domain",
      component: Whois
    },
    {
      name: "showlogs",
      path: "/showlogs/:virtname/:vid",
      component: ShowLogs
    },
    {
      name: "mailing-lists",
      path: "/mailing-lists",
      component: MailingLists
    },
    {
      path: "/:catchAll(.*)",
      redirect: { name: "home" }
    }
  ],
  scrollBehavior (to, from, savedPosition) {
     return { left: 0, top: 0 };
  }
});
