<template>
  <div id="accordion">
   <div :class="card_mode">
    <div :class="card_header" id="headingZero">
      <h5 class="mb-0">
        <i class="fa fa-lg fa-fw fa-house-chimney"></i>
        <button class="btn btn-link" data-toggle="collapse"
          data-target="#collapseZero" aria-expanded="true" aria-controls="collapseZero">
          <router-link v-on:click="setContent('Empty', 'SWCP Members Portal', {})" v-bind:to="{ name: 'home' }">Home</router-link>
        </button>
      </h5>
    </div>
    <div id="collapseZero" class="collapse" aria-labelledby="headingZero" data-parent="#accordion">
    </div>

  </div>
   <div :class="card_mode">
    <div :class="card_header" id="headingOne">
      <h5 class="mb-0">
        <i class="fa fa-lg fa-fw fa-coffee"></i>
        <button class="btn btn-link" data-toggle="collapse" 
                data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"
                v-on:click="setContent('Empty', 'Information', {})">
          Info
        </button>
      </h5>
    </div>
    <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
      <div class="card-body">
        <ul>
          <li class='dboffset'> <router-link v-bind:to="{ name: 'quickref' }">Quick Reference</router-link> </li>
          <li class='dboffset'> <router-link v-bind:to="{ name: 'subscribe-portal' }">Subscribe to the Portal</router-link></li>
        </ul>
      </div>
    </div>

  </div>
  <div :class="card_mode">
    <div :class="card_header" id="headingTwo">
      <h5 class="mb-0">
        <i class="fa fa-lg fa-fw fa-user"></i>
        <button class="btn btn-link collapsed" data-toggle="collapse" 
                data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo"
                v-on:click="setContent('Empty', 'Accounting Tools',{})">
          Account
        </button>
      </h5>
    </div>
    <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
      <div class="card-body">
         <ul>
            <li class='dboffset'> <router-link v-bind:to="{ name: 'account-info' }">Account Summary</router-link> </li>
            <li class='dboffset'> <router-link v-bind:to="{ name: 'account-addr'}">Set Account Address</router-link></li>
            <li class='dboffset'> <router-link v-bind:to="{ name: 'contacts' }">Account Contacts</router-link> </li>
            <li class='dboffset'> <router-link v-bind:to="{ name: 'secondaries' }">Secondaries</router-link> </li>
            <li class='dboffset'> <router-link v-bind:to="{ name: 'sec-passwords' }">Set Passwords for Secondaries</router-link> </li>
            <li class='dboffset'> <router-link v-bind:to="{ name: 'circuit-list' }">Circuits</router-link> </li>
            <li class='dboffset'> <router-link v-bind:to="{ name: 'ba-list' }">BUS Accounts</router-link> </li>
            <li class='dboffset'> <router-link v-bind:to="{ name: 'disk-usage' }">Disk Usage</router-link> </li>
            <li class='dboffset'> <router-link v-bind:to="{ name: 'change-email-password' }">Set Email Password</router-link></li>
            <li class='dboffset'> <router-link v-bind:to="{ name: 'change-panel-password' }">Set Panel Password</router-link></li>
            <li class='dboffset'> <router-link v-bind:to="{ name: 'send-auth-msg' }">Authenticated Message</router-link></li>
            <li class='dboffset'> <router-link v-bind:to="{ name: 'recovery-contacts' }">Set Recovery Addresses</router-link> </li>
         </ul>
      </div>
    </div>
  </div>
  <div :class="card_mode">
    <div :class="card_header" id="headingThree">
      <h5 class="mb-0">
        <i class="fa fa-lg fa-fw fa-credit-card"></i>
        <button class="btn btn-link collapsed" data-toggle="collapse" 
                data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree"
                v-on:click="setContent('Empty', 'Billing Tools',{})">
          Billing
        </button>
      </h5>
    </div>
    <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
      <div class="card-body">
         <ul>
            <li v-if="staff" class='dboffset'> <router-link v-bind:to="{ name: 'coworking-prods'}">Coworking Products</router-link></li>
            <li class='dboffset'> <router-link v-bind:to="{ name: 'billing-addr'}">Set Billing Address</router-link></li>
            <li class='dboffset'> <router-link v-bind:to="{ name: 'make-payment' }">Make a Payment</router-link></li>
            <li class='dboffset'> <router-link v-bind:to="{ name: 'edit-cc' }">Add/Edit Credit Card</router-link></li>
            <li class='dboffset'> <router-link v-bind:to="{ name: 'change-stmt-delivery' }" >Set Statement Delivery</router-link></li>
            <li class='dboffset'> <router-link v-bind:to="{ name: 'recent-payments' }">Recent Payments</router-link></li>
            <li class='dboffset'> <router-link v-bind:to="{ name: 'invoices' }">Invoices</router-link></li>
         </ul>
      </div>
    </div>
  </div>
  <div :class="card_mode">
    <div :class="card_header" id="headingFour">
      <h5 class="mb-0">
        <i class="fa fa-lg fa-fw fa-grip"></i>
        <button class="btn btn-link collapsed" data-toggle="collapse" 
                data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour"
                v-on:click="setContent('Empty', 'Domains',{})">
          Domains
        </button>
      </h5>
    </div>
    <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordion">
      <div class="card-body">
         <ul>
            <li class='dboffset'> <router-link v-bind:to="{ name: 'domain-list' }">Edit Domains</router-link> </li>
            <li class='dboffset'> <router-link v-bind:to="{ name: 'renew-domains' }">Renew Domains</router-link> </li>
            <li class='dboffset'> <router-link v-bind:to="{ name: 'domain-permissions' }">Domain Permissions</router-link> </li>
            <li class='dboffset'> <router-link v-bind:to="{ name: 'remove-permissions' }">Remove Permissions</router-link> </li>
            <li class='dboffset'> <router-link v-bind:to="{ name: 'whois', params: { domain: 'domain' } }">Whois Info</router-link> </li>
            <li class='dboffset'> <router-link v-bind:to="{ name: 'dom-help' }">Domain Help</router-link> </li>
            <li class='dboffset'> <router-link v-bind:to="{ name: 'dom-faq' }">Domain FAQ</router-link> </li>
         </ul>
      </div>
    </div>
  </div>
  <div :class="card_mode">
    <div :class="card_header" id="headingFive">
      <h5 class="mb-0">
        <i class="fa fa-lg fa-fw fa-envelope"></i>
        <button class="btn btn-link" data-toggle="collapse" 
                data-target="#collapseFive" aria-expanded="true" aria-controls="collapseFive"
                v-on:click="setContent('Empty', 'E-mail Tools',{})">
          Mail Tools
        </button>
      </h5>
    </div>

    <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordion">
      <div class="card-body">
        <ul>
            <li class='dboffset'> <router-link v-bind:to="{ name: 'email-aliases' }">Domain Email Aliases</router-link> </li>
            <li class='dboffset'> <router-link v-bind:to="{ name: 'temp-email' }">Temporary Email</router-link> </li>
            <li class='dboffset'> <router-link v-bind:to="{ name: 'temp-email-faq' }">Temporary Email FAQ</router-link> </li>
            <li class='dboffset'> <router-link v-bind:to="{ name: 'mailing-lists' }">Mailing Lists</router-link> </li>
            <li class='dboffset'> <a target="asf" href="https://members.swcp.com/mailfilter/">Anti-Spam & Forwarding</a> </li>
            <li class='dboffset'> <router-link v-bind:to="{ name: 'auto-responder' }">Autoresponder Setup</router-link> </li>
        </ul>
      </div>
    </div>
    <div :class="card_mode">
    <div :class="card_header" id="headingSix">
      <h5 class="mb-0">
        <i class="fa fa-lg fa-fw fa-globe"></i>
        <button class="btn btn-link" data-toggle="collapse" 
                data-target="#collapseSix" aria-expanded="true" aria-controls="collapseSix"
                v-on:click="setContent('Empty', 'Website Tools',{})">
          Websites/Databases
        </button>
      </h5>
    </div>

    <div id="collapseSix" class="collapse" aria-labelledby="headingSix" data-parent="#accordion">
      <div class="card-body">
        <ul>
            <li class='dboffset'> <router-link v-bind:to="{ name: 'domain-usage' }">Your Domains & Aliases</router-link> </li>
            <li class='dboffset'> <router-link v-bind:to="{ name: 'websites' }">Your Websites</router-link> </li>
            <li class='dboffset'> <router-link v-bind:to="{ name: 'databases' }">Your Databases</router-link> </li>
            <li class='dboffset'> <router-link v-bind:to="{ name: 'webappinstall' }">Install a Webapp</router-link> </li>
            <li class='dboffset'> <router-link v-bind:to="{ name: 'devsites' }">Devsites Summary</router-link> </li>
        </ul>
      </div>
    </div>
  </div>
    <div :class="card_mode">
    <div :class="card_header" id="headingSeven">
      <h5 class="mb-0">
        <i class="fa fa-lg fa-fw fa-bell-concierge"></i>
        <button class="btn btn-link" data-toggle="collapse" 
                data-target="#collapseSeven" aria-expanded="true" aria-controls="collapseSeven"
                v-on:click="setContent('Empty', 'Add Services',{})">
          Add Services
        </button>
      </h5>
    </div>
    <div id="collapseSeven" class="collapse" aria-labelledby="headingSeven" data-parent="#accordion">
      <div class="card-body">
        <ul>
            <li class='dboffset'> <router-link v-bind:to="{ name: 'domain-registration' }">Register or Transfer Domain</router-link> </li>
            <li class='dboffset'> <router-link v-bind:to="{ name: 'domain-registration-help' }">Domain Registration Help</router-link> </li>
            <li class='dboffset'> <router-link v-bind:to="{ name: 'add-secondaries' }">Add Secondary Accounts</router-link> </li>
            <li class='dboffset'> <router-link v-bind:to="{ name: 'add-webhosting' }">Add Web Hosting</router-link> </li>
        </ul>
      </div>
    </div>
  </div>

  <div :class="card_mode">
    <div :class="card_header" id="headingEight">
      <h5 class="mb-0">
        <i class="fa fa-lg fa-fw fa-wrench"></i>
        <button class="btn btn-link" data-toggle="collapse" 
                data-target="#collapseEight" aria-expanded="true" aria-controls="collapseEight"
                v-on:click="setContent('Empty', 'General Tools',{})">
          Misc Tools
        </button>
      </h5>
    </div>

    <div id="collapseEight" class="collapse" aria-labelledby="headingEight" data-parent="#accordion">
      <div class="card-body">
        <ul>
            <li class='dboffset'> <router-link v-bind:to="{ name: 'generate-passwords' }">Generate Passwords</router-link> </li>
            <li class='dboffset'> <router-link v-bind:to="{ name: 'pwned' }">Check Password</router-link> </li>
            <li class='dboffset'> <router-link v-bind:to="{ name: 'md5' }">Hash Encryptor</router-link> </li>
            <li class='dboffset'> <router-link v-bind:to="{ name: 'base64' }">Encrypt using Base64</router-link> </li>
            <li class='dboffset'> <router-link v-bind:to="{ name: 'unix-time' }">Unix Time Tools</router-link> </li>
        </ul>
      </div>
    </div>
  </div>
  </div>
</div>
</template>

<script>
   export default {
       name: "Sidebar",
       methods: {
          setContent(item, title, args) {
             this.$emit('setpage', item, title, args);
          }
       },
      data () {
          return {
             card_modes: {
                  light: "card",
                  dark:  "card bg-dark text-white"
              },
              card_headers: {
                  light: "card-header",
                  dark:  "card-header-dark"
              }
          }
      },
      computed: {
          operator ()        { return this.$store.state.operator; },
          current_user ()    { return this.$store.state.current_user; },
          staff()            { return this.$store.state.staff; },
          jwt ()             { return this.$store.state.jwt; },
          mode()             { return this.$store.state.mode; },
          card_mode()        { return this.card_modes[this.mode]; },
          card_header()      { return this.card_headers[this.mode]; }
      }
   }
</script>

<style>
.dboffset {
    margin: -30 5% 0 20%;
    display: block;
}
.bg-dark {
    background-color: #222 !important;
}
</style>
